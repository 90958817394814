import React, { useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { navigate, Link } from 'gatsby';
import amplifyConfig from '../amplify';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Layout from '../components/layout';
import useFormInput from '../components/use-form-input';
import useLoginStyles from '../components/use-login-styles';

const config = amplifyConfig();
Amplify.configure(config);

function ForgotPasswordPage({path}) {
  const classes = useLoginStyles();
  const username = useFormInput();
  const [form, setForm] = useState({ isSending: false,isSuccess: false, error: null }); 
  const isInvalidForm = !username.isValid; 
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setForm({ ...form, isSending: true });
    try {
      const res = await Auth.forgotPassword(username.value);
      setForm({ ...form, isSending: false, isSuccess: true, error: null });
      navigate('/reset-password');
    } catch(error) {
      const message = error.message || 'An error occured, please try again. If the error persists, contact the administrator';
      setForm({ ...form, isSending: false, isSuccess: false, error: message });
    }
  }
  return (
    <Layout path={path}>
      <Container component="main" maxWidth="xs">
        { form.error && <Alert severity="error">{form.error}</Alert> }
        { form.isSuccess && <Alert severity="success">
          We've sent you an email to reset your password
        </Alert> }
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              inputProps={{
                readOnly: Boolean(form.isSending),
              }}
              autoFocus
              fullWidth
              id="username"
              label="Username"
              margin="normal"
              name="username"
              required
              type="text"
              variant="outlined"
              value={username.value}
              onChange={username.onChange}
            />
            <div></div>
            <Button
              disabled={form.isSending || isInvalidForm}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleOnSubmit}
            >
              { form.isSending ? 'Sending...' : 'Verify identity' }
            </Button>
            <Button
              disabled={form.isSending}
              type="button"
              component={Link}
              to="/login"
              fullWidth
              variant="outlined"
              color="primary"
              className={classes.submit}
            >
              Cancel
            </Button>
          </form>
        </div>
      </Container>
    </Layout>
  );
}

export default ForgotPasswordPage;
